import React from 'react'
import * as PropTypes from 'prop-types'
import BlogTemplate from '../components/pages/blogTemplate'
import { graphql } from 'gatsby'

const BlogSk = ({ data }) => (<BlogTemplate data={data} lang={'sk'} />)
BlogSk.propTypes = { data: PropTypes.any }

export const query = graphql`{
  blog: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/blog/)/"}, frontmatter: {language: {eq: "sk"}}} limit: 100) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          language
          title
          perex
          date
          category
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          coveImage {
            childImageSharp {
              fixed( width: 500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`

export default BlogSk
