import React, { Component } from 'react'
import { uniq } from 'lodash'

import Layout from '../layout'
import FlipMove from 'react-flip-move'
import * as PropTypes from 'prop-types'
import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction, slugFormat } from '../../utilities'
import PageTitle from '../pageTitle'
import Reveal from '../reveal'
import LinkBtn from '../linkBtn/LinkBtn'
import Blog from '../blog/Blog'
import './Pages.scss';
import ContaktUs from '../contactUs/ContactUs'
import Footer from '../../layouts/footer'

class BlogTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategory: null,
      blogPosts: props.data.blog.edges.map((edge) => {
        const node = edge.node

        const lang = node.frontmatter.language
        const slug = `${lang === 'en' ? '/en' : ''}/blog/${slugFormat(node.frontmatter.title)}/`
        return {
          ...edge.node.frontmatter,
          slug: slug,
          id: edge.node != null ? edge.node.id : '',
        }
      }),
    }

    const allCategories = uniq(this.state.blogPosts.map((post) => post.category))

    if (typeof window !== 'undefined') {
      window.BLOG_ALL_CATEGORIES = allCategories
      if (window.BLOG_FORCE_UPDATE_CB != null) {
        window.BLOG_FORCE_UPDATE_CB()
      }
      window.BLOG_CATEGORY_CLICK_HANDLER = this.handleCategoryClick.bind(this)
    }

    this.handleCategoryClick = this.handleCategoryClick.bind(this)
  }

  handleCategoryClick(category) {
    this.setState({
      activeCategory: category,
    })
  }

  render() {
    let { lang } = this.props

    const allCategories = uniq(this.state.blogPosts.map((post) => post.category))

    const filteredBlogPosts = this.state.blogPosts.filter((post) => {
      return post.category === this.state.activeCategory || this.state.activeCategory === null
    })

    const sortedBlogPosts = filteredBlogPosts.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    const t = getTranslateFunction(lang)
    
    return (
      <Layout lang={lang} className={'mobile-menu-offset footer-hide  our-work'}>
        
        <section className="hp-blog blog-list margin-top">
          <div className="container">
            <div className="row mb-md-5 pb-4">
              <div className="col">
                <PageTitle title={t('Blog.Title')} description={ t('Blog.Description') } />
                <ul className="blog-list-categories">
                  <li className={this.state.activeCategory === null ? 'active' : ''}>
                    <span title-bak={t('Blog.AllArticles')} onClick={() => {
                      this.handleCategoryClick(null)
                    }}>{t('Blog.AllArticles')}</span>
                  </li>
                  {allCategories.map((category) => {
                    return (<li key={category} className={this.state.activeCategory === category ? 'active' : ''}>
                      <span title-bak={category} onClick={() => {
                        this.handleCategoryClick(category)
                      }}>{category}</span>
                    </li>)
                  })}
                </ul>
              </div>
            </div>

            <FlipMove
              typeName={'li'}
              className="row mb-0 mb-md-5">
              {sortedBlogPosts.map((blogPost) => {
                return ((
                  <Reveal otherClassName="col-md-6 col-lg-4 pb-4 pm-md-0 mb-4 mb-md-4" key={blogPost.id}>
                    <Blog post={blogPost} image={blogPost.thumbnail.childImageSharp.fluid} to={blogPost.slug} />
                  </Reveal>
                ))
              })
              }
            </FlipMove>
            
            <Reveal>
              <section className="casestudy-block cat-block about-us-projects blog-cat">
                <div className="container">
                  <h3>Všetky naše referencie</h3>
                  <LinkBtn>
                    <TranslatedLink to="/en/our-works/featured">Pozrite si práce</TranslatedLink>
                  </LinkBtn>
                </div>
              </section>
            </Reveal>

            <section className='d-block d-md-none contact-us-width-fix'>
              <ContaktUs
                LeftSideTitle='category'
                LeftSideSubTitle={'Design'}
                LeftSideLink={'/en/services/design'}
                leftClassName={'d-none'}
                linkRight="/en/contact"
                rightSecondLink="/en/brief"
              />
            </section>

            {/*<div className="row text-center">*/}
            {/*  {this.state.blogPosts.length > 30 ? (<div className="col">*/}
            {/*    <TranslatedLink to="/en/blogAll" className="link red" title-bak={t('Blog.MoreBtn')}>Pozrite si viac</TranslatedLink>*/}
            {/*  </div>) : null}*/}
            {/*</div>*/}
          </div>
            </section>
            
        <footer>
            <Footer lang={lang} />
        </footer>
        
      </Layout>
    )
  }
}

BlogTemplate.propTypes = { data: PropTypes.any }

export default BlogTemplate
