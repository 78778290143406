import React from 'react'
import Img from 'gatsby-image'
import { TranslatedLink } from '../TranslatedLink'
import { getSlug } from '../../getSlug'
import '../../lib/react-alice-carousel/scss/alice-carousel.scss'
import Tilt from 'react-tilt'
import './Blog.scss';


const Blog = ({ post, image, to }) => {

  const description = post.description ? post.description : post.perex
  return (
    <article className='item'>
      <TranslatedLink to={to} title-bak={post.title} className="blog-item">
        <Tilt
          className={'tilt'}
          options={{
            max: 25,
            glare: true,
            'max-glare': 0.5,
            reverse: true,
            scale: 1.05,
          }}>
          <div className="img-wrapper">
            <Img
              className="image"
              fluid={image}
              alt={post.title}
              style={{ height: 330, width: '100%' }}
              imgStyle={{
                objectFit: "contain",
                objectPosition: "50% bottom",
              }}

            />
          </div>
        </Tilt>
        <span className="tag"><img className="image" src="../images/svg/icon_tag.svg" alt="Casestudy" />{post.category}</span>
        <h3>{post.title}</h3>
        <p>{description}</p>
      </TranslatedLink>
    </article>
  )
}

Blog.defaultProps = {
  post: [],
  image: null,
  to: null,
}

export default Blog;
